import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Navigation from "../components/Navigation"

const Header = ({ siteTitle }) => (
  <header className="container">
    <div className="header-content header-content--left">
      <h1>
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>

    <Navigation/>
    
    <div className="header-content header-content--right">
     Fresh, handmade, local, pasta.
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
