import React from "react"
import { Link } from "gatsby"

export default () => {
  return (
    <div>
      <div className="Navigation text-center">
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/order">Order</Link>
      </div>

      <div className="side-link">
        <Link to="/order"><span className="underline">Order &rarr;</span></Link>
      </div>
    </div>
  )
}